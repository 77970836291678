.bannerHome {
  background-image: url('./assets/landingpage/top_background3.jpg');
  background-size: 100%;
  background-position: top;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #d6dce5;
}

.navbar {
  font-weight: bolder;
  font-size: large;
  background: transparent;
}

.navbar.active {
  font-weight: bolder;
  font-size: large;
  background: white;
}

.program {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: 100vh;
  flex-direction: column;
}

.programTitle {
  text-align: center;
  font-weight: bolder;
  margin-top: -100px;
  font-size: 40px;
  color: white;
  text-shadow: 1px 1px 2px black, 0 0 25px #6d862e, 0 0 5px #91a16e;
}
.claimTitle {
  text-align: center;
  font-weight: bolder;
  margin-top: 50px;
  font-size: 40px;
  color: white;
  text-shadow: 1px 1px 2px black, 0 0 25px #6d862e, 0 0 5px #91a16e;
}
.cardProgram {
  display: flex;
  justify-content: center;
}

.profile {
  margin-top: 100px;
  margin-bottom: 20px;
  width: 100vw;
}

.profileCard {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  cursor: pointer;
}

.small {
  font-size: small;
}

.modal-body {
  /* margin: 4px; */
  padding: 0 !important;
}
