.time {
  position: relative;
  padding: 4px 0 0 0;
  margin-top: 22px;
  list-style: none;
}

.time > li:nth-child(even) {
  position: relative;
  margin-bottom: 50px;
  height: 180px;
  right: -50px;
}

.time > li:nth-child(odd) {
  position: relative;
  margin-bottom: 50px;
  height: 180px;
  left: -80px;
}

.time > li .time-panel {
  position: relative;
  float: left;
  width: 41%;
  padding: 0 20px 20px 30px;
  text-align: right;
}

.time > li .time-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}

.time > li .time-image {
  z-index: 100;
  position: absolute;
  left: 50%;
  border: 7px solid #3b5998;
  border-radius: 100%;
  background-color: #3b5998;
  box-shadow: 0 0 5px #4582ec;
  width: 200px;
  height: 200px;
  margin-left: -100px;
}

.time > li .time-image h4 {
  margin-top: 12px;
  font-size: 10px;
  line-height: 14px;
}

.time > li.time-inverted > .time-panel {
  float: right;
  padding: 0 30px 20px 20px;
  text-align: left;
}

.time > li.time-inverted > .time-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}

.time > li.time-inverted > .time-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}

.time > li:last-child {
  margin-bottom: 0;
}

.time .time-heading h4 {
  margin-top: 22px;
  margin-bottom: 4px;
  padding: 0;
  color: #b3b3b3;
}

.time .time-heading h4.subheading {
  margin: 0;
  padding: 0;
  text-transform: none;
  font-size: 18px;
  color: #333333;
}

.time .time-body > p,
.time .time-body > ul {
  margin-bottom: 0;
  color: #808080;
}
/*Style for even div.line*/
.time > li:nth-child(odd) .line:before {
  content: "";
  position: absolute;
  top: 60px;
  bottom: 0;
  left: 690px;
  width: 4px;
  height: 340px;
  background-color: #3b5998;
  -ms-transform: rotate(-44deg); /* IE 9 */
  -webkit-transform: rotate(-44deg); /* Safari */
  transform: rotate(-44deg);
  box-shadow: 0 0 5px #4582ec;
}
/*Style for odd div.line*/
.time > li:nth-child(even) .line:before {
  content: "";
  position: absolute;
  top: 60px;
  bottom: 0;
  left: 450px;
  width: 4px;
  height: 340px;
  background-color: #3b5998;
  -ms-transform: rotate(44deg); /* IE 9 */
  -webkit-transform: rotate(44deg); /* Safari */
  transform: rotate(44deg);
  box-shadow: 0 0 5px #4582ec;
}
/* Medium Devices, .visible-md-* */
@media (min-width: 992px) and (max-width: 1199px) {
  .time > li:nth-child(even) {
    margin-bottom: 0px;
    min-height: 0px;
    right: 0px;
  }
  .time > li:nth-child(odd) {
    margin-bottom: 0px;
    min-height: 0px;
    left: 0px;
  }
  .time > li:nth-child(even) .time-image {
    left: 0;
    margin-left: 0px;
  }
  .time > li:nth-child(odd) .time-image {
    left: 690px;
    margin-left: 0px;
  }
  .time > li:nth-child(even) .time-panel {
    width: 76%;
    padding: 0 0 20px 0px;
    text-align: left;
  }
  .time > li:nth-child(odd) .time-panel {
    width: 70%;
    padding: 0 0 20px 0px;
    text-align: right;
  }
  .time > li .line {
    display: none;
  }
}
/* Small Devices, Tablets */
@media (min-width: 768px) and (max-width: 991px) {
  .time > li:nth-child(even) {
    margin-bottom: 0px;
    min-height: 0px;
    right: 0px;
  }
  .time > li:nth-child(odd) {
    margin-bottom: 0px;
    min-height: 0px;
    left: 0px;
  }
  .time > li:nth-child(even) .time-image {
    left: 0;
    margin-left: 0px;
  }
  .time > li:nth-child(odd) .time-image {
    left: 520px;
    margin-left: 0px;
  }
  .time > li:nth-child(even) .time-panel {
    width: 70%;
    padding: 0 0 20px 0px;
    text-align: left;
  }
  .time > li:nth-child(odd) .time-panel {
    width: 70%;
    padding: 0 0 20px 0px;
    text-align: right;
  }
  .time > li .line {
    display: none;
  }
}
/* Custom, iPhone Retina */
@media only screen and (max-width: 767px) {
  .time > li:nth-child(even) {
    margin-bottom: 0px;
    min-height: 0px;
    right: 0px;
  }
  .time > li:nth-child(odd) {
    margin-bottom: 0px;
    min-height: 0px;
    left: 0px;
  }
  .time > li .time-image {
    position: static;
    width: 150px;
    height: 150px;
    margin-bottom: 0px;
  }
  .time > li:nth-child(even) .time-image {
    left: 0;
    margin-left: 0;
  }
  .time > li:nth-child(odd) .time-image {
    float: right;
    left: 0px;
    margin-left: 0;
  }
  .time > li:nth-child(even) .time-panel {
    width: 100%;
    padding: 0 0 20px 14px;
  }
  .time > li:nth-child(odd) .time-panel {
    width: 100%;
    padding: 0 14px 20px 0px;
  }
  .time > li .line {
    display: none;
  }
}
